<template>
    <div class="remind">
        <div class="remind-box">
            <p>申报内容</p>
            <p>
                <span class="lable">甲方</span>
                <span>{{ data.contractA }}</span>
            </p>
            <p>
                <span class="lable">乙方</span>
                <span v-if="data.typeB != 9">
                    {{ $tableDataHandle.typeB(data.typeB) }}
                </span>
                <span v-if="data.typeB == 9">{{ data.contractB }}</span>
            </p>
            <p>
                <span class="lable">合同金额</span>
                <span>{{ data.declareAmount }}</span>
            </p>
            <p>
                <span class="lable">商务</span>
                <span>{{ data.adminName }}</span>
            </p>
            <p>
                <span class="lable">申报时间</span>
                <span>{{ data.createTime }}</span>
            </p>
            <p class="content">
                <span class="lable" style="color:#333333">提醒内容</span>
                <el-input
                    type="textarea"
                    v-model="desc"
                    rows="5"
                    style="width:350px;"
                ></el-input>
            </p>
        </div>
        <div class="bottom-but">
            <el-button
                round
                type="primary"
                size="mini"
                style="background:none;border-color:#D5D6DF;color:#666666;"
                @click="onClose"
                >取消</el-button
            >
            <el-button
                round
                type="primary"
                style="background:#2370EB;border-color:#2370EB;color:#fff;margin-right:32px"
                size="mini"
                @click="onSubmit"
                >确定</el-button
            >
        </div>
    </div>
</template>
<script>
import { messageTips } from '@/api/user/business/audit.js';
export default {
    data() {
        return {
            data: {},
            desc: '',
        };
    },
    methods: {
        getData(data) {
            this.data = data;
            this.desc = '';
        },
        onSubmit() {
            if (!this.desc) {
                this.$message.error('请输入提醒内容');
            } else {
                let data = {
                    param: {
                        tipsContent: this.desc,
                        contractTitle: this.data.contractTitle,
                        adminId: this.data.adminId,
                    },
                };
                messageTips(data).then((res) => {
                    if (res.code == 200) {
                        this.$message.success('操作成功');
                    }
                    this.onClose();
                });
            }
        },
        onClose() {
            this.$emit('close');
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.remind {
    width: 100%;
    .remind-box {
        padding-left: 64px;
        padding-top: 22px;
        font-size: 14px;
        color: #333333;
        p {
            padding-bottom: 20px;
            .lable {
                color: #666666;
                display: inline-block;
                width: 90px;
            }
        }
        .content {
            display: flex;
        }
    }
    .bottom-but {
        width: 100%;
        line-height: 48px;
        background: #fff;
        border-top: 1px solid #dee0e3;
        margin-top: 16px;
        text-align: right;
        button {
            width: 90px;
        }
    }
}
</style>
