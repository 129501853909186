import http from '@/utils/http.js'

// 所有业绩申报
export function declareAll(data) {
    return http({
        url: "/jasoboss/performance/declare/list",
        method: "post",
        data
    })
}
// 手动匹配到款的回款列表信息
export function similarityList(data) {
    return http({
        url: "/jasoboss/performance/declare/list/arrive/amount",
        method: "post",
        data
    })
}
// 手动审核
export function declareAudit(data) {
    return http({
        url: "/jasoboss/performance/declare/manual/many/audit",
        method: "post",
        data
    })
}
// 消息提醒
export function messageTips(data) {
    return http({
        url: "/jasoboss/performance/declare/message/tips",
        method: "post",
        data
    })
}
// 申请驳回
export function performance(data) {
    return http({
        url: "/jasoboss/performance/declare/return/performance",
        method: "post",
        data
    })
}
// 单个手动匹配到款的回款列表信息
export function similarityListAlone(data) {
    return http({
        url: "/jasoboss/performance/declare/list/similarity",
        method: "post",
        data
    })
}
// 单个手动审核
export function declareAuditAlone(data) {
    return http({
        url: "/jasoboss/performance/declare/manual/audit",
        method: "post",
        data
    })
}