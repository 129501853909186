<template>
    <div class="matching">
        <div class="matching-box">
            <div class="information">
                <p>
                    <span style="color:#666;margin-right:10px">甲方</span
                    ><span>{{ data.contractA }}</span>
                </p>
                <p>
                    <span style="color:#666;margin-right:10px">乙方</span>
                    <span v-if="data.typeB != 9">
                        {{ $tableDataHandle.typeB(data.typeB) }}
                    </span>
                    <span v-if="data.typeB == 9">{{ data.contractB }}</span>
                </p>
                <p>
                    <span style="color:#666;margin-right:10px">合同金额</span
                    ><span>{{ data.declareAmount }}</span>
                </p>
                <p>
                    <span style="color:#666;margin-right:10px">已到款金额</span
                    ><span>{{ data.paymentAmount }}</span>
                </p>
            </div>

            <p class="Tips">
                该合同暂不能与到款信息完全匹配，可在下方列表中手动关联到款信息，在成功匹配后可通过审核
            </p>
            <div class="title">
                <p>相似的到款信息，点击勾选</p>
                <p>
                    已勾选
                    <span style="color:#F5A623">{{ checklist.length }}条</span>
                    收款，金额为
                    <span style="color:#F5A623">{{ totalAmount }}元</span>
                </p>
            </div>
            <div class="table">
                <el-table
                    @selection-change="handleSelectionChange"
                    :data="gridData"
                    height="100%"
                    :header-cell-style="{
                        background: '#FAFAFA',
                        color: '#333333',
                        'font-size': '14px',
                        'font-weight': '500',
                    }"
                >
                    <el-table-column type="selection" width="55" align="center">
                    </el-table-column>
                    <el-table-column
                        property="arriveAmount"
                        label="交易金额（元）"
                    ></el-table-column>
                    <el-table-column
                        property="contractA"
                        label="交易公司"
                    ></el-table-column>
                    <el-table-column
                        property="contractB"
                        label="入账公司"
                    ></el-table-column>
                    <el-table-column
                        property="staffAdminName"
                        label="员工"
                    ></el-table-column>
                    <el-table-column
                        property="remark"
                        label="备注"
                        show-overflow-tooltip
                    ></el-table-column>
                    <el-table-column
                        property="updateTime"
                        label="交易日"
                        show-overflow-tooltip
                    ></el-table-column>
                </el-table>
            </div>
        </div>
        <div class="bottom-but">
            <el-button
                round
                type="primary"
                size="mini"
                style="background:none;border-color:#D5D6DF;color:#666666;"
                @click="onClose"
                >取消</el-button
            >
            <el-button
                round
                type="primary"
                style="background:#2370EB;border-color:#2370EB;color:#fff;margin-right:32px"
                size="mini"
                @click="onSubmit"
                >确定</el-button
            >
        </div>
    </div>
</template>
<script>
import {
    similarityListAlone,
    declareAuditAlone,
} from '@/api/user/business/audit.js';
export default {
    data() {
        return {
            data: {},
            gridData: [],
            checklist: [],
            totalAmount: 0,
        };
    },
    methods: {
        getData(data) {
            this.data = data;
            let datas = {
                param: {
                    arriveAmount: data.declareAmount - data.paymentAmount,
                    contractA: data.contractA,
                },
            };

            if (data.typeB == 9) {
                datas.param.contractB = data.contractB;
            } else {
                datas.param.contractB = this.$tableDataHandle.typeB(data.typeB);
            }
            similarityListAlone(datas).then((res) => {
                this.gridData = res.data;
            });
        },
        handleSelectionChange(val) {
            this.checklist = val;
            this.totalAmount = 0;
            for (let i in val) {
                this.totalAmount += Number(val[i].arriveAmount);
            }
        },
        onSubmit() {
            let data = {
                param: {
                    id: this.data.id,
                },
            };
            if (this.checklist.length == 0) {
                this.$message.error('请勾选匹配数据');
            } else {
                data.param.arriveDOList = [];
                for (let i in this.checklist) {
                    data.param.arriveDOList.push({
                        id: this.checklist[i].id,
                        arriveTime: this.checklist[i].arriveTime,
                    });
                }
                declareAuditAlone(data).then((res) => {
                    if (res.code == 200) {
                        this.$message.success('匹配成功');
                        this.onClose();
                    }
                });
            }
        },
        onClose() {
            this.$emit('close');
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.matching {
    width: 100%;
    .matching-box {
        width: 100%;
        .information {
            width: 100%;
            padding: 24px 0 30px 0;
            display: flex;
            p {
                flex: 1;
                margin-left: 35px;
                color: #333;
            }
        }
        .Tips {
            margin-left: 35px;
            font-size: 14px;
            font-weight: 500;
            color: #333333;
            line-height: 20px;
            padding-bottom: 30px;
        }
        .title {
            display: flex;
            padding: 0 35px;
            flex-direction: row;
            justify-content: space-between;
        }
        .table {
            width: 820px;
            height: 343px;
            margin-left: 35px;
            border: 1px solid #e5e5e5;
            border-radius: 3px;
            margin-top: 24px;
        }
    }
    .bottom-but {
        width: 100%;
        line-height: 48px;
        background: #fff;
        border-top: 1px solid #dee0e3;
        margin-top: 16px;
        text-align: right;

        button {
            width: 90px;
        }
    }
}
</style>
