<template>
    <div class="addachv">
        <el-form
            style="flex:1;margin-top:20px;margin-left: 40px;margin-bottom:80px"
            label-position="left"
            label-width="120px"
        >
            <el-form-item label="关联合同">
                <span class="text">{{ data.contractTitle }}</span>
            </el-form-item>
            <el-form-item label="甲方">
                <span class="text">{{ data.contractA }}</span>
            </el-form-item>
            <el-form-item label="打款人">
                <span class="text">{{ data.payContract }}</span>
            </el-form-item>
            <el-form-item label="乙方">
                <span class="text">
                    {{ $tableDataHandle.typeB(data.typeB) }}
                </span>
            </el-form-item>
            <el-form-item v-if="data.typeB == 9">
                <span class="text">{{ data.contractB }}</span>
            </el-form-item>
            <el-form-item label="合同金额">
                <span class="text">{{ data.declareAmount }}</span>
            </el-form-item>
            <el-form-item label="备注">
                <span class="text">{{ data.remark }}</span>
            </el-form-item>
            <el-form-item label="部门">
                <span class="text">{{ data.departmentName }}</span>
            </el-form-item>
            <el-form-item label="商务">
                <span class="text">{{ data.adminName }}</span>
            </el-form-item>
            <el-form-item label="状态">
                <span class="text">{{
                    data.status == 1
                        ? '审核中'
                        : data.status == 2
                        ? '已驳回'
                        : data.status == 3
                        ? '已审核'
                        : '- -'
                }}</span>
            </el-form-item>
        </el-form>
        <div class="bottom-but">
            <el-button
                round
                type="primary"
                size="mini"
                style="background:none;border-color:#D5D6DF;color:#666666;margin-left:260px"
                @click="onClose"
                >关闭</el-button
            >
            <el-button
                round
                type="primary"
                size="mini"
                style="background:#2370EB;border-color:#2370EB;color:#fff;text-align:center"
                @click="onSub"
                v-if="btnP.Match"
                >手动匹配到款</el-button
            >
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            data: {},
            btnP: {},
        };
    },
    methods: {
        getData(row, btn) {
            this.data = row;
            this.btnP = btn;
        },
        onSub() {
            this.$emit('matching', this.data);
        },
        onClose() {
            this.$emit('close');
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.addachv {
    width: 100%;
    .el-input {
        width: 270px;
    }
    .bottom-but {
        width: 100%;
        line-height: 48px;
        background: #fff;
        border-top: 1px solid #dee0e3;
        z-index: 1000;
        bottom: 0;
        position: fixed;
        button {
            z-index: 1000;
            min-width: 80px;
        }
    }
}
</style>
