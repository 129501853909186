<template>
    <div class="matching">
        <div class="matching-box">
            <div class="information" v-for="item in data">
                <p>
                    <span style="color: #666; margin-right: 10px">甲方</span
                    ><span>{{ item.contractA }}</span>
                </p>
                <p>
                    <span style="color: #666; margin-right: 10px">乙方</span>
                    <span v-if="item.typeB != 9">
                        {{ $tableDataHandle.typeB(item.typeB) }}
                    </span>
                    <span v-if="item.typeB == 9">{{ data.contractB }}</span>
                </p>
                <p>
                    <span style="color: #666; margin-right: 10px">合同金额</span
                    ><span>{{ item.declareAmount }}</span>
                </p>
            </div>
            <div style="text-align: right; margin-right: 35px">
                合同总金额：{{ totalAmount }}
            </div>
            <p class="Tips">
                该合同暂不能与到款信息完全匹配，可在下方列表中手动关联到款信息，在成功匹配后可通过审核
            </p>

            <div class="table">
                <el-table
                    highlight-current-row
                    :data="gridData"
                    height="100%"
                    :header-cell-style="{
                        background: '#FAFAFA',
                        color: '#333333',
                        'font-size': '14px',
                        'font-weight': '500',
                    }"
                >
                    <el-table-column label="选择" width="55" align="center">
                        <template slot-scope="scope">
                            <el-radio v-model="tableRadio" :label="scope.row"
                                ><i></i
                            ></el-radio>
                        </template>
                    </el-table-column>
                    <el-table-column
                        property="arriveAmount"
                        label="交易金额（元）"
                    ></el-table-column>
                    <el-table-column
                        property="contractA"
                        label="交易公司"
                    ></el-table-column>
                    <el-table-column
                        property="contractB"
                        label="入账公司"
                    ></el-table-column>
                    <el-table-column
                        property="staffAdminName"
                        label="员工"
                    ></el-table-column>
                    <el-table-column
                        property="remark"
                        label="备注"
                        show-overflow-tooltip
                    ></el-table-column>
                    <el-table-column
                        property="updateTime"
                        label="交易日"
                        show-overflow-tooltip
                    ></el-table-column>
                </el-table>
            </div>
        </div>
        <div class="bottom-but">
            <el-button
                round
                type="primary"
                size="mini"
                style="background: none; border-color: #d5d6df; color: #666666"
                @click="onClose"
                >取消</el-button
            >
            <el-button
                round
                type="primary"
                style="
                    background: #2370eb;
                    border-color: #2370eb;
                    color: #fff;
                    margin-right: 32px;
                "
                size="mini"
                @click="onSubmit"
                >确定</el-button
            >
        </div>
    </div>
</template>
<script>
import { similarityList, declareAudit } from '@/api/user/business/audit.js';
export default {
    data() {
        return {
            data: {},
            gridData: [],
            checklist: [],
            totalAmount: 0,
            tableRadio: '',
        };
    },
    methods: {
        getData(row) {
            this.tableRadio = '';
            this.totalAmount = 0;
            this.data = row;
            for (let i in row) {
                this.totalAmount += row[i].declareAmount;
            }

            let data = {
                param: {
                    arriveAmount: this.totalAmount,
                },
            };

            similarityList(data).then((res) => {
                this.gridData = res.data;
            });
        },

        onSubmit() {
            if (this.tableRadio == '') {
                this.$message.error('请点击选择匹配数据');
            } else {
                let data = {
                    param: {
                        declareDOList: this.data,
                        arriveDO: { id: this.tableRadio.id },
                    },
                };
                declareAudit(data).then((res) => {
                    if (res.code == 200) {
                        this.$message.success('匹配成功');
                        this.onClose();
                    }
                });
            }
        },
        onClose() {
            this.$emit('close');
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.matching {
    width: 100%;
    max-height: 600px;
    overflow: auto;
    .matching-box {
        width: 100%;
        .information {
            width: 100%;
            padding: 10px 0 10px 0;
            display: flex;
            p {
                flex: 1;
                margin-left: 35px;
                color: #333;
            }
        }
        .Tips {
            margin-left: 35px;
            font-size: 14px;
            font-weight: 500;
            color: #333333;
            line-height: 20px;
            padding-bottom: 10px;
        }
        .title {
            display: flex;
            padding: 0 35px;
            flex-direction: row;
            justify-content: space-between;
        }
        .table {
            width: 820px;
            height: 343px;
            margin-left: 35px;
            border: 1px solid #e5e5e5;
            border-radius: 3px;
            margin-top: 24px;
        }
    }
    .bottom-but {
        width: 100%;
        line-height: 48px;
        background: #fff;
        border-top: 1px solid #dee0e3;
        margin-top: 16px;
        text-align: right;

        button {
            width: 90px;
        }
    }
}
</style>
